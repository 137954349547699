header {
	margin-bottom: 100px;
}

.nav {
	position: fixed;
	height: 84px;
	left: 0;
	right: 0;
	z-index: 1000;
	background: var(--tertiary-bg-color);
}

.nav-menu {
	margin-top: var(--sm-margin);
}

.nav-menu-scrolling {
	margin-top: 0 !important;
	margin-bottom: var(--sm-margin) !important;
}

.mobile-menu {
	position: fixed;
	display: none;
	top: -200px;
	left: 0;
	right: 0;
	z-index: 500;
	padding: 20px 0;
	background: var(--tertiary-bg-color);
}