@import './shared/css/shared.css';

html, header, body, button, footer {
    font-family: 'Roboto', sans-serif;
    color: var(--font-color);
}

body {
    margin: 0;
    padding: 0;
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}