.highlight-card-container {
    position: relative;
    width: 60%;
    margin: var(--lg-margin);
}

.highlight-card-overlay {
    width: 100%;
    height: 100%;
    background-color: var(--primary-bg-color);
    opacity: 0.85;
}

@media only screen and (max-width: 680px) {
    .highlight-card-container {
        width: 95%;
        margin: var(--lg-margin) auto;
    }
}