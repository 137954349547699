img {
	width: auto;
	height: auto;
	max-height: 100%;
	max-width: 100%;
}

.highlight-image-container {
    width: 28%;
    margin: var(--lg-margin);
}

@media only screen and (max-width: 680px) {
    .highlight-image-container {
        position: absolute;
        right: 0;
        margin: 0 var(--lg-margin);
        z-index: 100;
        opacity: .85;
        max-height: 60px;
    }
    
    .highlight-image-container img {
    	position: absolute;
    	right: var(--md-margin);
    	top: 0;
    	bottom: 0;
    	margin: auto;
    	max-height: 52px;
    }
}