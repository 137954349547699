:root {
    --sm-font-size: 11px;
    --md-font-size: 12px;
    --lg-font-size: 14px;

    --sm-margin: 6px;
    --md-margin: 12px;
    --lg-margin: 18px;

    --sm-padding: 6px;
    --md-padding: 12px;
    --lg-padding: 18px;

    --sm-radius: 6px;
    --md-radius: 12px;
    --lg-radius: 18px;

    --primary-color: #12b6e2;
    --secondary-color: #78d813;
    --tertiary-color: #ffa726;
    --warning-color: #fcd303;
    --critical-color: #fc1703;

    --max-content-width: 1300px;
}

@import './default.css';
@import './fonts.css';
@import './layout.css';
@import './text.css';
@import './animations.css';
@import './button.css';
@import './form.css';
@import './color.css';