.document-container {
    padding-bottom: var(--lg-padding);
}

.document-container pre {
    margin: var(--lg-margin);
    padding: var(--lg-padding);
    border-radius: var(--sm-radius);
    border: 2px solid var(--tertiary-bg-color);
    overflow: scroll;
    max-height: 50vh;
    white-space: pre-wrap;
}

.document-container hr {
    width: 100%;
    background-color: var(--primary-color);
}

.document-container a {
    color: var(--primary-color);
    text-decoration: none;
}

.document-container a:hover {
    filter: brightness(90%);
}

.document-container code {
    color: var(--tertiary-color);
    background: brightness(90%);
    font-weight: bold;
    font-size: var(--lg-font-size);
}

.pages-nav-container {
    overflow-y: scroll;
    max-height: 50vh;
}

.pages-nav-container::-webkit-scrollbar {
    width: 12px;
    background-color: var(--tertiary-bg-color);
    border-radius: var(--md-radius);
    -webkit-appearance: none;
    -webkit-overflow-scrolling: auto;
}

.pages-nav-container::-webkit-scrollbar-thumb {
    background-color: var(--secondary-color);
    border-radius: var(--md-radius);
}

.pages-nav {
    margin: var(--md-margin) var(--sm-margin);
    padding: 0 0 0 var(--md-margin);
    list-style-type: square;
}

.pages-nav li {
    margin: var(--sm-margin);
    padding: 0;
}

.pages-link {
    color: var(--font-color);
    text-decoration: none;
}

.pages-link:hover {
    color: var(--secondary-color);
}

.wise-referral-container {
    background-color: #163301;
}

@media only screen and (max-width: 762px) {

    .hide-ad-mb {
    	display: none !important;
	}
}