.nav-left, .nav-right {
	height: 100%;
	z-index: 100;
	opacity: .85;
	margin: 0 !important;
	position: relative;
}

.nav-left {
	float: left;
}

.nav-right {
	float: right;
}