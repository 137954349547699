form, input, textarea {
	font-family: 'Roboto', sans-serif;
    font-weight: 500;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.form {
	display: block;
	padding: var(--sm-padding);
	max-width: var(--max-content-width);
}

.form-group {
	position: relative;
	display: flex;
	flex-wrap: wrap;
	align-items: stretch;
	width: 100%;
}

.form-label, .form-warning, .form-error {
	font-size: var(--sm-font-size);
	margin: var(--sm-margin) var(--md-margin);
	opacity: 0.8;
}

.form-warning {
	color: yellow;
}

.form-error {
	color: red;
}

.form-input {
	width: 100%;
	font-size: var(--md-font-size);
	border-radius: var(--sm-radius);
	border: none;
	resize: none;
	padding: var(--md-padding);
	margin: var(--sm-margin);
	color: var(--font-color);
}

.form-input:disabled {
	opacity: 0.5;
	cursor: default;
}

.form-input:focus {
    outline: 0;
}