body {
    transition: background 1s;
}

div, img, a, p, hr {
    transition: height 1s, width 1s, padding 1s, margin 1s, top 1s, bottom 1s, left 1s, right 1s, background 1s, opacity 1s;
}

.no-transition {
	transition: none !important;
}