.btn {
	display: block;
    color: var(--font-color);
    background-color: var(--secondary-bg-color);
    border: none;
    font-size: var(--md-font-size);
    font-weight: 500;
    padding: var(--md-padding);
    margin: var(--sm-margin);
    transition: color 0.25s, background 0.25s;
    text-align: center;
}

.btn:hover {
    cursor: pointer;
    filter: brightness(90%);
}

.btn:disabled {
    cursor: not-allowed;
    filter: brightness(60%);
}

.btn:focus {
    outline: 0;
}

.btn-sm {
    font-size: var(--sm-font-size) !important;
    padding: var(--sm-padding) !important;
}

.btn-lg {
    font-size: var(--lg-font-size) !important;
    padding: var(--lg-padding) !important;
}

.btn-img-icon {
	height: calc(var(--sm-font-size) * 2) !important;
}

.btn-img-sm {
	height: calc(var(--sm-font-size) * 4) !important;
}

.btn-img-md {
	height: calc(var(--md-font-size) * 4) !important;
}

.btn-img-lg {
	height: calc(var(--lg-font-size) * 4) !important;
}

.btn-img-sm, .btn-img-md, .btn-img-lg {
	width: auto !important;
}

.btn-primary {
    background-color: var(--primary-color) !important;
}

.btn-secondary {
    background-color: var(--secondary-color) !important;
}

.btn-tertiary {
    background-color: var(--tertiary-color) !important;
}

.btn-no-background {
    background-color: rgba(0,0,0,0) !important;
}

.btn-primary-underline {
    background: none !important;
    border-radius: 0 !important;
    border-bottom: 1px solid var(--primary-color);
}

.btn-secondary-underline {
    background: none !important;
    border-radius: 0 !important;
    border-bottom: 1px solid var(--secondary-color);
}

.btn-tertiary-underline {
    background: none !important;
    border-radius: 0 !important;
    border-bottom: 1px solid var(--tertiary-color);
}

.btn-primary-hover:hover, .btn-primary-underline:hover {
    color: var(--primary-color) !important;
    filter: none !important;
}

.btn-secondary-hover:hover, .btn-secondary-underline:hover {
    color: var(--secondary-color) !important;
    filter: none !important;
}

.btn-tertiary-hover:hover, .btn-tertiary-underline:hover {
    color: var(--tertiary-color) !important;
    filter: none !important;
}

.btn-primary-outline {
    background: none !important;
    color: var(--primary-color);
    border: 1px solid var(--primary-color) !important;
}

.btn-secondary-outline {
    background: none !important;
    color: var(--secondary-color);
    border: 1px solid var(--secondary-color) !important;
}

.btn-tertiary-outline {
    background: none !important;
    color: var(--tertiary-color);
    border: 1px solid var(--tertiary-color) !important;
}

.btn-primary-outline:hover {
    background-color: var(--primary-color) !important;
    color: #ffffff !important;
    filter: none !important;
}

.btn-secondary-outline:hover {
    background-color: var(--secondary-color) !important;
    color: #ffffff !important;
    filter: none !important;
}

.btn-tertiary-outline:hover {
    background-color: var(--tertiary-color) !important;
    color: #ffffff !important;
    filter: none !important;
}