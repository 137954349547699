/*
* Background color declarations
*/

.primary {
    background-color: var(--primary-color);
}

.secondary {
    background-color: var(--secondary-color);
}

.tertiary {
    background-color: var(--tertiary-color);
}

.warning {
	background-color: var(--warning-color);
	color: var(--font-black-color);
}

.critical {
	background-color: var(--critical-color);
	color: var(--font-black-color);
}

.bg-primary {
    background-color: var(--primary-bg-color);
}

.bg-secondary {
    background-color: var(--secondary-bg-color);
}

.bg-tertiary {
    background-color: var(--tertiary-bg-color);
}

/*
* Opacity declarations
*/

.o-0 {
	opacity: 0;
}

.o-1 {
	opacity: 0.1;
}

.o-2 {
	opacity: 0.2;
}

.o-3 {
	opacity: 0.3;
}

.o-4 {
	opacity: 0.4;
}

.o-5 {
	opacity: 0.5;
}

.o-6 {
	opacity: 0.6;
}

.o-7 {
	opacity: 0.7;
}

.o-8 {
	opacity: 0.8;
}

.o-9 {
	opacity: 0.9;
}

.o-10 {
	opacity: 1;
}