.card {
    border-radius: var(--sm-radius);
    text-align: left;
    overflow: hidden;
}

.card-section {
    width: 100%;
    padding: 0;
    margin: 0;
}