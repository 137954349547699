html {
	scroll-behavior: smooth;
}

header {
	height: 0px;
}

body {
	background: var(--primary-bg-color);
	-webkit-text-size-adjust: 100%;
	-moz-text-size-adjust: none;
	-ms-text-size-adjust: 100%;
}

a, a:link, a:hover, a:visited, a:active {
	color: inherit;
	text-decoration: none;
	-webkit-appearance: none;
}

input {
	-webkit-appearance: none;
}

img {
	width: auto;
	height: auto;
}