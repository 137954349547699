p, h1, h2, h3, h4, h5, h6, li {
    padding: var(--md-padding);
    margin: 0;
}

.text-sm {
	font-size: var(--sm-font-size) !important;
}

.text-md {
	font-size: var(--md-font-size) !important;
}

.text-lg {
	font-size: var(--lg-font-size) !important;
}

.text-left {
    text-align: left !important;
}

.text-center {
    text-align: center !important;
}

.text-right {
    text-align: right !important;
}

.text-a-middle {
    vertical-align: middle !important;
}

.text-no-wrap {
	white-space: nowrap !important;
}

.text-primary {
	color: var(--primary-color);
}

.text-secondary {
	color: var(--secondary-color);
}

.text-tertiary {
	color: var(--tertiary-color);
}

@media only screen and (max-width: 680px) {
	
	.text-mb-left {
    	text-align: left !important;
	}
	
	.text-mb-center {
	    text-align: center !important;
	}
	
	.text-mb-right {
	    text-align: right !important;
	}
	
	.text-a-mb-middle {
	    vertical-align: middle !important;
	}
	
	.text-mb-primary {
		color: var(--primary-color);
	}
	
	.text-mb-secondary {
		color: var(--secondary-color);
	}
	
	.text-mb-tertiary {
		color: var(--tertiary-color);
	}
}