/*
* Flex declarations
*/

.flex {
    display: flex;
    flex-wrap: nowrap;
		justify-content: center;
}

.flex-wrap {
    flex-wrap: wrap;
}

.flex-start {
    justify-content: flex-start !important;
}

.flex-end {
    justify-content: flex-end !important;
}

.flex-space-between {
    justify-content: space-between !important;
}

.flex-column {
	flex-direction: column;
}

.flex-a-self-center {
	align-self: center !important;
}

.flex-a-items-center {
    align-items: center !important;
}

.flex-a-self-start {
	align-self: flex-start !important;
}

.flex-a-items-start {
    align-items: flex-start !important;
}

.flex-a-self-end {
	align-self: flex-end !important;
}

.flex-a-items-end {
    align-items: flex-end !important;
}

.flex-g-1 {
	flex-grow: 1;
}

.flex-g-2 {
	flex-grow: 2;
}

.flex-g-3 {
	flex-grow: 3;
}

.flex-g-4 {
	flex-grow: 4;
}

.flex-g-5 {
	flex-grow: 5;
}

.flex-o-first {
	order: 1;
}

.flex-o-second {
	order: 2;
}

.flex-o-last {
	order: 3;
}

@media only screen and (max-width: 680px) {
	
    .flex-mb-wrap {
	    flex-wrap: wrap;
	}

	.flex-mb-start {
		justify-content: flex-start !important;
	}
	
	.flex-mb-end {
		justify-content: flex-end !important;
	}

	.flex-mb-column {
		flex-direction: column !important;
	}
	
	.flex-a-mb-center {
		align-self: center !important;
	    align-items: center !important;
	}
	
	.flex-a-mb-start {
		align-self: flex-start !important;
	    align-items: flex-start !important;
	}
	
	.flex-a-mb-end {
		align-self: flex-end !important;
	    align-items: flex-end !important;
	}

	.flex-g-mb-1 {
		flex-grow: 1;
	}

	.flex-g-mb-2 {
		flex-grow: 2;
	}

	.flex-g-mb-3 {
		flex-grow: 3;
	}

	.flex-g-mb-4 {
		flex-grow: 4;
	}

	.flex-g-mb-5 {
		flex-grow: 5;
	}
	
	.flex-o-mb-first {
		order: 1;
	}
	
	.flex-o-mb-second {
		order: 2;
	}
	
	.flex-o-mb-last {
		order: 3;
	}
}

/*
* Float declarations
*/

.float-right, .float-mb-right {
    float: right;
}

.float-left, .float-mb-left {
    float: left;
}

@media only screen and (max-width: 680px) {
    .float-mb-right, .float-mb-left {
        float: none;
    }
}

/*
* Display declarations
*/

.d-block {
	display: block !important;
}

.d-inline {
	display: inline !important;
}

.d-none {
	display: none !important;
}

.d-hidden {
	display: hidden !important;
}

.pos-absolute {
	position: absolute;
}

.pos-relative {
	position: relative;
}

.overflow-hidden {
	overflow: hidden;
}

@media only screen and (max-width: 680px) {

    .d-mb-block {
    	display: block !important;
	}
	
	.d-mb-none {
		display: none !important;
	}
	
	.d-mb-hidden {
		display: hidden !important;
	}
}

/*
* Size declarations
*/

.h-1 {
    height: 10%;
}

.h-2 {
    height: 20%;
}

.h-3 {
    height: 30%;
}

.h-4 {
    height: 40%;
}

.h-5 {
    height: 50%;
}

.h-6 {
    height: 60%;
}

.h-7 {
    height: 70%;
}

.h-8 {
    height: 80%;
}

.h-9 {
    height: 90%;
}

.h-10 {
    height: 100%;
}

.w-1 {
    width: 10%;
}

.w-2 {
    width: 20%;
}

.w-3 {
    width: 30%;
}

.w-4 {
    width: 40%;
}

.w-5 {
    width: 50%;
}

.w-6 {
    width: 60%;
}

.w-7 {
    width: 70%;
}

.w-8 {
    width: 80%;
}

.w-9 {
    width: 90%;
}

.w-10 {
    width: 100%;
}

.w-quarter {
    width: 25%;
}

.w-third {
    width: 33.33%;
}

.w-max {
    max-width: var(--max-content-width);
}

@media only screen and (max-width: 680px) {

    .w-mb-1 {
    	width: 10% !important;
	}
	
	.w-mb-2 {
	    width: 20% !important;
	}
	
	.w-mb-3 {
	    width: 30% !important;
	}
	
	.w-mb-4 {
	    width: 40% !important;
	}
	
	.w-mb-5 {
	    width: 50% !important;
	}
	
	.w-mb-6 {
	    width: 60% !important;
	}
	
	.w-mb-7 {
	    width: 70% !important;
	}
	
	.w-mb-8 {
	    width: 80% !important;
	}
	
	.w-mb-9 {
	    width: 90% !important;
	}
	
	.w-mb-10 {
	    width: 100% !important;
	}
	
	.w-mb-quarter {
	    width: 25% !important;
	}
	
	.w-mb-third {
	    width: 33.33% !important;
	}
}

.r-sm {
	border-radius: var(--sm-margin);
}

.r-md {
	border-radius: var(--md-margin);
}

.r-lg {
	border-radius: var(--lg-margin);
}

.r-circle {
	border-radius: 1000px;
}

/*
* Padding variables
*/

.p-sm {
    padding: var(--sm-padding);
}

.px-sm {
    padding-left: var(--sm-padding);
    padding-right: var(--sm-padding);
}

.py-sm {
    padding-top: var(--sm-padding);
    padding-bottom: var(--sm-padding);
}

.p-md {
    padding: var(--md-padding);
}

.px-md {
    padding-left: var(--md-padding);
    padding-right: var(--md-padding);
}

.py-md {
    padding-top: var(--md-padding);
    padding-bottom: var(--md-padding);
}

.p-lg {
    padding: var(--lg-padding);
}

.px-lg {
    padding-left: var(--lg-padding);
    padding-right: var(--lg-padding);
}

.py-lg {
    padding-top: var(--lg-padding);
    padding-bottom: var(--lg-padding);
}

/*
* Margin variables
*/

.m-auto {
    margin: auto;
}

.m-none {
    margin: 0 !important;
}

.m-sm {
    margin: var(--sm-margin);
}

.mx-sm {
    margin-left: var(--sm-margin);
    margin-right: var(--sm-margin);
}

.my-sm {
    margin-top: var(--sm-margin);
    margin-bottom: var(--sm-margin);
}

.m-md {
    margin: var(--md-margin);
}

.mx-md {
    margin-left: var(--md-margin);
    margin-right: var(--md-margin);
}

.my-md {
    margin-top: var(--md-margin);
    margin-bottom: var(--md-margin);
}

.m-lg {
    margin: var(--lg-margin);
}

.mx-lg {
    margin-left: var(--lg-margin);
    margin-right: var(--lg-margin);
}

.my-lg {
    margin-top: var(--lg-margin);
    margin-bottom: var(--lg-margin);
}

hr {
    width: 75%;
    height: 1px;
    margin: var(--sm-margin) 0;
    opacity: 0.5;
    border: none;
}

/*
* Gap
*/

.gap-sm {
	margin: calc(var(--sm-margin) * -1) !important;
	width: calc(100% + var(--sm-margin)) !important;
	height: calc(100% + var(--sm-margin)) !important;
}

.gap-sm > * {
	margin: var(--sm-margin) !important;
}

.gap-md {
	margin: calc(var(--md-margin) * -1) !important;
	width: calc(100% + var(--md-margin)) !important;
	height: calc(100% + var(--md-margin)) !important;
}

.gap-md > * {
	margin: var(--md-margin) !important;
}

.gap-lg {
	margin: calc(var(--lg-margin) * -1) !important;
	width: calc(100% + var(--lg-margin)) !important;
	height: calc(100% + var(--lg-margin)) !important;
}

.gap-lg > * {
	margin: var(--lg-margin) !important;
}