.highlight-container {
    position: relative;
    width: 100%;
    overflow: hidden;
}

.bg-image {
    position: absolute;
    width: 100%;
    height: auto;
    top: 0;
    left: 0;
}

.content {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 100;
}

.accent {
    position: absolute;
    width: 40%;
    height: 100%;
    top: 0;
}

.accent-left {
    left: -20%;
    transform: skewX(60deg);
}

.accent-right {
    right: -20%;
    transform: skewX(120deg);
}

.expand-btn {
    width: 100%;
    background-color: var(--tertiary-bg-color);
    padding: var(--sm-padding);
    margin: 0;
    border-radius: 0;
    font-weight: 700;
}